@import './variables';

.App {
  text-align: center;
  background-color: #F2F2F2;
  background-image: url("assets/images/backgroundLines.svg");
}

.overflow-hidden {
  overflow: hidden;
}

.page-not-found {
  height: calc(100vh - $headerHeight - $footerHeight);
  display: flex;
  justify-content: center;
  align-items: center;
}
