@import '../mixins';
@import '../variables';

.game-page {
  box-sizing: border-box;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  height: calc(100vh - $headerHeight - $footerHeight);
  justify-content: space-between;
  padding: 0 48px;


  &__info-modal-button, &__game-info-panel, &__demo-game-info {
    box-sizing: border-box;
    max-width: 365px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #fff;
    height: 58px;
    padding: 0 26px;
    margin-top: 63px;
    border-radius: 14px;
    box-shadow: 0 4px 11px 0 #00000014;
  }

  &__info-modal-button {
    height: max-content;
    min-height: 56px;
    max-width: 182px;
    min-width: 130px;
  }

  &__game-info-panel {
    max-width: 320px;
    display: flex;
    gap: 50px;
    justify-content: space-between;

    & .game-timer {
      border: 2px solid #FCD19F;
      border-radius: 6px;
      padding: 5px;
    }

    & .clicks {
      min-width: 40px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  &__game-info-panel div {
    min-width: 40px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;
    font-size: 14px;
    font-weight: 600;
    color: #727272;
  }

  &__demo-game-info {
    max-width: 350px;
    margin-top: 34px;
    flex-direction: column;
    height: max-content;
    padding: 26px 30px;
  }

  &__demo-game-info h1 {
    width: 100%;
    margin-top: 0;
    font-size: 16px;
    font-weight: 600;
    color: #3B3E46;
    text-align: left;
  }

  &__demo-game-info span {
    text-align: left;
    color: #3B3E46B2;
    font-size: 14px;
  }

  &__game-container {
    padding: 0 20px 0 20px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 37px;
  }

  &__game-info-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__demo-game-difference_mobile {
    display: none;
  }

  &__action-buttons {
    max-width: 490px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    gap: 22px;
  }

  &__play-demo {
    height: 60px;
    width: 320px;
    max-width: 370px;
    background: linear-gradient(180deg, rgba(255,193,122,1) 0%, rgba(255,165,61,1) 100%);
    color: white;
    font-size: 18px;
    font-weight: 600;
    border: 0;
    border-radius: 14px;
    filter: drop-shadow(0 4px 0 #E69438);
    cursor: pointer;
  }

  &__remove-button {
    font-size: 17px;
    font-weight: 600;
    color: #fff;
    cursor: pointer;
    width: 228px;
    height: 60px;
    border: 0;
    border-radius: 34px;
    background: linear-gradient(180deg, rgba(133,227,252,1) 0%, rgba(69,197,234,1) 100%);
  }

  .disabled {
    background: linear-gradient(180deg, rgb(183, 183, 183) 0%, rgb(147, 147, 147) 100%);
    cursor: auto;
  }
}

@include mobile {
  .game-page {
    grid-template-columns: 1fr;
    align-content: flex-start;
    padding: 0;

    & > :nth-child(2) { order: 2 }

    & > :nth-child(3) { order: 1 }

    &__info-modal-button {
      position: absolute;
      left: -1000px;
      top: -1000px;
    }

    &__game-info-panel {
      min-width: 320px;
      margin-top: 20px;
      margin-bottom: 0;
    }

    &__demo-game-difference {
      display: none;
    }

    &__demo-game-difference_mobile &__demo-game-info {
      margin-top: 0;
      margin-bottom: 20px;
    }


    &__game-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 20px;
      height: calc(100vh - 70px - 80px - 110px);

      @media (max-height: 770px) {
        height: calc(100vh - 100px)
      }
    }

    &__game-info-container {
      align-items: center;
    }

    &__demo-game-info {
      margin-top: 20px;
      margin-bottom: 0;
    }

    &__action-buttons {
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      gap: 20px
    }
  }
}

@include lowHeight {
  .game-page {
    height: 100vh;
  }
}