@mixin mobile {
  @media (max-width: 1080px) { @content; }
}

@mixin desktop {
  @media (min-width: 1080px) { @content; }
}

@mixin lowHeight {
  @media (max-height: 770px) { @content; }
}

@mixin action-button {
  height: 60px;
  width: 100%;
  max-width: 370px;
  background: linear-gradient(180deg, rgba(255,193,122,1) 0%, rgba(255,165,61,1) 100%);;
  color: white;
  font-size: 17px;
  font-weight: 600;
  border: 0;
  border-radius: 14px;
  filter: drop-shadow(0 4px 0 #E69438);
  cursor: pointer;
}


