@import '../../mixins';

.game-area {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  border-radius: 14px;
  margin-bottom: 34px;
  width: 490px;
  height: 490px;
  gap: 5px;
  overflow: hidden;

  .hidden {
    visibility: hidden;
  }

  &__square {
    z-index: 1;
    width: 94px;
    height: 94px;
    background: linear-gradient(180deg, rgba(249,143,120,1) 0%, rgba(247,105,96,1) 100%);
  }

  &__game-image {
    width: 100%;
    z-index: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
  }

  &__game-question-block {
    position: absolute;
    z-index: 3;
    background-color: rgba(0,0,0,0.4);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    color: #000000;
    padding: 30px 0;
  }

  &__game-question-block .game-question {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: max-content;
    width: max-content;
    min-width: 120px;
    max-width: 300px;
    padding: 22px 30px;
    border-radius: 6px;
    background-color: #FFF;
    font-size: 16px;
    font-weight: 600;

    &__additional-text {
      margin-top: 10px;
      font-size: 14px;
      font-weight: 400;
      color: #3B3E46;
    }

    &--demo {
      @extend .game-question;

      @include mobile {
        display: none;
      }

      & p {
        font-size: 14px;
        font-weight: 400;
        color: #3B3E46;
      }
    }

    &--subtext {
      color: white;
    }
  }

  .game-question--demo-mobile {
    display: none;
    color: black;
    background-color: #fff;
    border-radius: 14px;
    margin: 0 26px 20px 26px;
    padding: 22px 30px;

    & p {
      font-size: 14px;
      color: #3B3E46;
    }

    @include mobile {
      display: block;
    }
  }

  @include mobile {
    width: 320px;
    height: 320px;
    min-height: 320px;

    &__square {
      width: 60px;
      height: 60px;
      background: linear-gradient(180deg, rgba(249,143,120,1) 0%, rgba(247,105,96,1) 100%);
    }

    &__game-question-block .game-question {
      max-width: 220px;
    }
  }
}