@import '../../mixins.scss';

.modal {
  background-color: rgba(0,0,0,0.4);
  z-index: 10;
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  overflow: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  &__content {
    position: relative;
    background-color: #FFFFFF;
    width: 70%;
    max-width: 400px;
    max-height: max-content;
    margin: 0 auto;
    padding: 20px;
    border-radius: 14px;
  }

  &__close {
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
    color: #aaaaaa;
    float: right;
    font-size: 25px;
    font-weight: bold;
 }
 
 &__close:hover, 
 &__close:focus {
     color: #000;
     text-decoration: none;
     cursor: pointer;
 }
}