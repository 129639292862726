@import '../../mixins';
@import '../../variables';

.try-it {
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  width: 100%;
  height: calc(100vh - $headerHeight - $footerHeight);
  padding: 50px 48px;

  @media (max-height: 860px) {
    height: 720px;
  }

  &__info-container {
    display: flex;
    flex-direction: column;
    gap: 34px;
    margin-right: 23px;
    align-items: flex-end;
    margin-bottom: 25px;

    .app-info-container {
      display: flex;
      align-items: center;
      gap: 15px;
      margin-bottom: 30px;
    }

    .info-block, .app-block {
      box-sizing: border-box;
      background-color: white;
      border-radius: 14px;
      height: max-content;
      padding: 26px 36px;
      max-width: 420px;
    }

    .info-block h2, .app-block h2{
      font-size: 16px;
      font-weight: 600;
      color: #3B3E46;
      text-align: left;
      margin-top: 0;
    }

    .info-block p, .app-block p{
      font-size: 14px;
      color: #3B3E46B2;
      margin-bottom: 0;
      text-align: left;
      margin-top: 0;
    }

    .info-block div {
      padding-bottom: 15px;
    }

    .app-block h2{
      margin-bottom: 4px;
    }

    .app-text {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      & ul {
        font-size: 14px;
        color: #3B3E46B2;
        margin-bottom: 10px;
        padding-left: 22px;
        text-align: left;
      }
    }

    .app-qr {
      display: contents;
      border-radius: 4px;

      & img {
        border-radius: 4px;
      }
    }

    .app-download-container {
      box-sizing: border-box;
      position: relative;
      display: flex;
      justify-content: space-between;

      &__image {
        max-width: 145px;
      }
    }
  }

  &__question-container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    width: 660px;
    height: 660px;
    border-radius: 14px;
    margin-left: 23px;

    .question-img {
      width: 100%;
      border-radius: 14px;
      z-index: 1;
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
    }

    .question-text {
      box-sizing: border-box;
      position: relative;
      z-index: 2;
      background-color: #FFB156;
      border-radius: 6px;
      padding: 12px 34px;
      margin-bottom: 24px;
      color: white;
      font-size: 16px;
      font-weight: 600;
    }
  }
}

@include mobile {
  .try-it {
    flex-direction: column;
    padding: 20px;
    height: 100%;

    & > :nth-child(1) { order: 2 }
    & > :nth-child(2) { order: 1 }

    &__question-container {
      width: 100%;
      max-width: 330px;
      height: 330px;
      margin-bottom: 36px;
      margin-left: auto;
      margin-right: auto;
    }

    &__info-container {
      align-items: center;
      margin-left: auto;
      margin-right: auto;

      .info-block, .app-block {
        padding: 26px 30px;
        max-width: 330px;
      }

      .app-info-container {
        margin-bottom: 20px;
      }

      .app-qr {
        display: none;
      }
    }

    .app-download-container {
      &__image {
        max-width: 130px;
      }
    }
  }
}