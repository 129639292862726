@import '../../variables';

.footer-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: $footerHeight;
  font-size: 14px;

  & a {
    text-decoration: none;
    color: #3B3E46CC;
  }

  & span {
   padding-right: 5px;
   padding-left: 5px;
  }
}