body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: #EFEFEF;
}

::-webkit-scrollbar-thumb {
  background: linear-gradient(120deg, rgba(255,193,122,1) 0%, rgba(255,165,61,1) 100%);
  border-radius: 20px;
}

::-webkit-scrollbar-thumb:hover {
  background: #FFA53D;
}

textarea:focus, input:focus{
  outline: none;
}
