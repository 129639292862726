@import '../../mixins';

.answer-button {
  height: 60px;
  width: 228px;
  max-width: 370px;
  background: linear-gradient(180deg, rgba(255,193,122,1) 0%, rgba(255,165,61,1) 100%);;
  color: white;
  font-size: 18px;
  font-weight: 600;
  border: 0;
  border-radius: 14px;
  cursor: pointer;

  .disabled {
    background: linear-gradient(180deg, rgb(183, 183, 183) 0%, rgb(147, 147, 147) 100%);
    cursor: auto;
  }
}

.answer-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 10px;

  &__title {
    font-size: 16px;
    font-weight: 600;
    color: #3B3E46;
  }

  &__subtitle {
    font-size: 14px;
    font-weight: 400;
    color: #3B3E46B2;
    text-align: start;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  &__form-lable {
    font-size: 14px;
    font-weight: 600;
    color: #3B3E46B2;
    margin-bottom: 8px;
  }

  &__form-input {
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 12px;
    padding: 20px 26px;
    background: #F0F0F0;
    border: 2px solid #EBE7E4;
    border-radius: 9px;
    -webkit-text-fill-color: #3B3E4666;
    font-size: 15px;
    font-family: 'Poppins';
    resize: none;
  }
  &__form-textarea{
    box-sizing: border-box;
    width: 100%;
    padding: 20px 26px;
    background: #F0F0F0;
    border: 2px solid #EBE7E4;
    -webkit-text-fill-color: #3B3E4666;
    border-radius: 9px;
    height: 200px;
    font-size: 14px;
    font-family: 'Poppins';
    resize: none;
    margin-bottom: 30px;
  }

  &__action-button {
    @include action-button
  }
}

.answer-error-msg {
  text-align: start;
  width: max-content;

  & h3 {
    font-size: 16px;
    font-weight: 600;
    color: #3B3E46;
    margin: 0
  }

  & span {
   font-size: 14px;
   color: #3B3E46B2;
  }
}