@import '../../mixins.scss';
@import '../../variables';

.navbar {
  position: relative;
  z-index: 9;
  height: $headerHeight;
  background-color: #FFFFFF;
  box-shadow: 0 4px 10px 0 #00000014;
  
  &__container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    align-items: center;
    margin-left: 42px;
    margin-right: 42px;
    height: 100%;
  }

  &__logo a{
    display: flex;
    gap: 12px;
    align-items: center;
    font-family: 'Fredoka', sans-serif;;
    font-size: 32px;
    font-weight: 400;
    background-image: linear-gradient(95deg, #FFA53D, #FFCA8C);
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
  }

  &__title {
    font-size: 24px;
    font-weight: 700;
    background-image: linear-gradient(95deg, #FFC17A, #FFA53D);
    background-size: 100%;
    background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent; 
    -moz-text-fill-color: transparent;
  }

  &__nav-elements {
    display: flex;
    justify-content: flex-end;
  }

  &__nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;
    padding-left: 0;
  }

  &__nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }

  &__nav-elements ul a {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(59, 62, 70, 0.9);
    text-decoration: none;
  }

  &__nav-elements ul a.active {
    color: #FFAF52;
  }

  // Mobile menu
  &__mobile-menu-icon {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__mobile-menu-elements {
    z-index: 1;
    width: calc(100% - 80px);
    background-color: #FFFFFF;
    position: absolute;
    top: 81px;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 40px;
  }

  &__mobile-menu-elements ul::after {
    width: 100%;
    content: '';
    padding-top: 30px;
    border-bottom: 1px solid #D9D9D9;
  }

  &__mobile-menu-elements ul {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    list-style-type: none;
    padding-left: 0;
  }

  &__mobile-menu-elements ul li:not(:last-child) {
    margin-bottom: 24px;
  }

  &__mobile-menu-elements ul a {
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: rgba(59, 62, 70, 0.9);
    text-decoration: none;
  }

  &__mobile-menu-elements ul a.active {
    color: #FFAF52;
  }

  @include mobile {
    &__title {
      display: none;
    }

    &__nav-elements ul {
      display: none;
    }
  }

  @include desktop {
    &__mobile-menu-icon {
      display: none;
    }

    &__mobile-menu-elements {
      display: none;
    }
  }
}