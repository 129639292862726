@import '../../mixins';

.info-modal {
  .info-modal__header {
    @include lowHeight {
      top: -75px;
    }

    position: absolute;
    left: 50%;
    top: -90px;
    transform: translateX(-50%);
  }

  &__title {
    color: #3B3E46;
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 0;
    margin-top: 80px;
  }

  &__content {
    max-width: 370px;
    margin-left: auto;
    margin-right: -10px;
    padding-right: 20px;
    max-height: 300px;
    overflow: auto;
    mask-image: linear-gradient(to bottom, transparent 0%, rgb(255, 255, 255) 5px, rgb(255, 255, 255) calc(100% - 48px), transparent 100%);
    margin-bottom: 7px;
  }

  &__content p {
    text-align: left;
    font-size: 16px;
    font-weight: 400;
    color: #3B3E46B2;
  }

  &__action-button {
    @include action-button;
  }
}

.info-button {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 6px;
  cursor: pointer;

  &__text {
    font-size: 16px;
    font-weight: 600;
    color: #3B3E46B2;
  }
}